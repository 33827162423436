import AOS from 'aos';
import { FC, Fragment, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Background from './bg';
import Footer from './footer';
import Header from './header';

type Props = {
  children: ReactNode;
};

const Layout: FC<Props> = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <link rel="shortcut icon" href="favicon.png" type="image/x-icon" />
      </Helmet>
      <Header />

      <main className="main-content fix">
        <Background />
        {children}
      </main>
      <Footer />
    </Fragment>
  );
};
export default Layout;
